<template>
  <div class="newProject">
    <div class="title">项目信息</div>
    <div class="flex_center_col">
      <div class="project_title">项目名称</div>
      <div class="content">{{user_info.organization.name}}</div>
    </div>
    <div class="flex_center_col">
      <div class="project_title">所属行业</div>
      <div class="content">{{user_info.organization.industrial_classification.name}}</div>
    </div>
    <div class="flex_center_col">
      <div class="project_title">所在地区</div>
      <div
        class="content"
      >{{user_info.organization.province_name}}/{{user_info.organization.city_name}}/{{user_info.organization.district_name}}</div>
    </div>
    <div class="flex_center_col">
      <div class="project_title">详细地址</div>
      <div class="content">{{user_info.organization.address}}</div>
    </div>
    <div class="flex_center_col">
      <div class="project_title">负责人</div>
      <div class="content">{{user_info.organization.contact}}</div>
    </div>
    <div class="flex_center_col">
      <div class="project_title">联系方式</div>
      <div class="content">{{user_info.organization.phone}}</div>
    </div>
    <div class="btn">
      <el-button
        type="danger"
        icon="el-icon-edit"
        @click="clickProject"
        v-if="this.$store.state.current_project.role_id===5"
      >解散项目</el-button>
      <el-button
        type="primary"
        icon="el-icon-edit"
        @click="editProject"
        v-if="this.$store.state.current_project.role_id===5||this.$store.state.current_project.role_id===8"
      >编辑项目</el-button>
    </div>
  </div>
</template>

<script>
import { user, dataCation } from "@/utils/api";
import location from "@/utils/province_city_district.json";
import { isTelAvailable } from "../../utils/verification";
export default {
  name:'projectDetail',
  data() {
    return {};
  },
  components: {},
  computed: {
    user_info() {
      return this.$store.state.current_project;
    },
  },
  methods: {
    // 点击编辑项目 并且传参判断 进行回调
    editProject() {
      this.$router.push({
        name: "newProject",
        params: {
          action: "edit",
        },
      });
    },
    // 点击解散项目
    clickProject() {
      let that = this;
      this.$confirm("此操作将解散该项目, 是否继续?", "提示", {
        confirmButtonText: "确定解散",
        cancelButtonText: "取消",
        confirmButtonClass: "queryBtn",
        type: "info",
      })
        // 并把第一个项目设置为当前项目
        .then(async () => {
          const res = await this.$http.get(
            `${user.dissolve}?organization_id=${this.$store.state.current_project.organization_id}`
          );
          if (res.code == 0) {
            let projectArr = this.$store.state.projectArr.filter((item) => {
              return item.value != this.$store.state.projectValue;
            });
            let user_info = this.$store.state.user_info;
            let members = user_info.members.filter((item) => {
              return item.organization_id != this.$store.state.projectValue;
            });
            user_info.members = members;
            let projectValue = projectArr[0].value;
            let current_project = this.$store.state.user_info.members[0];
            this.$store.commit("setUserInfo", user_info);
            this.$store.commit("setCurrentProject", current_project);
            this.$store.commit("setProjectArr", projectArr);
            this.$store.commit("setprojectValue", projectValue);
          }
          this.$message({
            type: "success",
            message: "已解散!",
          });
          this.$router.push("homePage");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消该操作",
          });
        });
    },
  },
  // 删除项目并更新 然后项目为第一个项目
  delProject() {
  },
};
</script>
<style lang='scss' scoped>
.newProject {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 830px;
  height: 542px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  border: 1px solid #e6ecf5;
  margin: 60px auto;
  .title {
    font-size: $fontSizeMap-20;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 28px;
    margin-top: 19px;
    margin-bottom: 50px;
  }
  .flex_center_col {
    width: 420px;
    // justify-content: space-around;
    line-height: 60px;
    font-size: $fontSizeMap-14;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    color: #666666;
  }
  .project_title {
    width: 100px;
    margin-right: 30px;
    text-align: right;
    font-size: $fontSizeMap-14;
  }
}
</style>
